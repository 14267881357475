.NewNote form {
    padding-bottom: 15px;
}

.NewNote form textarea {
    height: 300px;
    font-size: 24px;
}

#newNote h2{
    color: #000000;
}