body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2.pageTitle {
  display: inline-block;
  font-size: 4.2rem;
  font-weight: 100;
  padding: 10px 0;
  margin-bottom: 44px;
  text-align: center;
  border-bottom: solid 1px #ccc;
  border-top: solid 1px #ccc;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  color: #555;
  letter-spacing: 0;
}

.paperCutMenu {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}